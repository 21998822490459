import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import kcg from "../../assets/images/logo/kcg.png";
import whatsAppIcon from "../../assets/images/icons/whatsapp.png";

const Header = () => {
  const [showParent, setShowParent] = useState(false);
  return (
    <header className="site-header">
      <div className="header-banner">
        <div className="container">
          <div className="large">
            <div className="section-content-container">
              <div className="section-content">
                <nav className="nav">
                  <ul className="nav-menu flex">
                    <li>
                      <div className="header-brand">
                        <div className="site-brand">
                          <a href="/" className="brand-logo block">
                            <img src={kcg} alt="Kampala Grammar School Badge" />
                          </a>
                        </div>
                      </div>
                    </li>
                    <li>
                      <a href="/fees-structure" className="nav-main-link bold">
                        <h3>Fees Structure</h3>
                      </a>
                    </li>
                    <li>
                      <div className="menu-wrapper relative">
                        <label
                          onClick={() => setShowParent(true)}
                          htmlFor="toggle-responsive-menu"
                          className="nav-label bold"
                        >
                          &#9776;
                        </label>
                        <input type="checkbox" id="toggle-responsive-menu" />
                        <div
                          onClose={() => setShowParent(false)}
                          showParent={showParent}
                          className={`nav-inner ${
                            showParent ? "showParent" : ""
                          }`}
                        >
                          <div className="hide-menu">
                            <button
                              onClick={() => setShowParent(false)}
                              className="hide-menu-btn button"
                            >
                              X
                            </button>
                          </div>
                          <ul
                            className="site-menu site-menu-items bold"
                            onClick={() => setShowParent(false)}
                          >
                            <li className="site-menu-item">
                              <a href="/">Home</a>
                            </li>
                            <li className="site-menu-item">
                              <a href="/gallery">Gallery</a>
                            </li>
                            <li className="site-menu-item">
                              <a href="/kcg-staff">School Staff</a>
                            </li>
                            <li className="site-menu-item">
                              <a href="/kcg-prefects">School Prefects</a>
                            </li>
                            <li className="site-menu-item">
                              <a href="/admission">Admission Procedure</a>
                            </li>
                            <li className="site-menu-item">
                              <a href="/contact">Contact Us &amp; Directions</a>
                            </li>
                            <li className="site-menu-item">
                              <a href="/headteacher-messages">
                                Head Teacher's Message
                              </a>
                            </li>
                            <li className="site-menu-item">
                              <a href="/fees-structure">
                                Fees Structure &amp; Sections
                              </a>
                            </li>
                            <li className="site-menu-item">
                              <a href="/about">About School &amp; Foundation</a>
                            </li>

                            <li className="site-menu-item">
                              <a href="/calendar">
                                School Programs and Calendar
                              </a>
                            </li>

                            <li className="site-menu-item">
                              <a href="/updates">
                                School Communications &amp; Updates
                              </a>
                            </li>
                            <li className="site-menu-item whatsapp-button button">
                              <a
                                href="https://wa.me/256772402260"
                                title="WhatsApp Us"
                              >
                                <img src={whatsAppIcon} class="icon" />
                                <span>WhatsApp Direct</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default withRouter(Header);
