import React from "react";
import { Slide } from "react-reveal";

const FeesStructure = () => {
  return (
    <section className="fees-structure section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <Slide left>
                <div className="section-head center">
                  <h3 className="text-shadow light-blue">
                    School Fees Structure
                  </h3>
                  <p className=" margin-top-20 width-600 margin-auto">
                    Our fees are pocket friendly and affordable. You get
                    value for money when you consider the quality of education
                    your child is to receive while with us, the meals given and
                    the care received by all learners.
                  </p>
                </div>
              </Slide>
              <div className="section-body section-spacing-60 no-padding-top width-800 margin-auto">
                <div className="fees-tables-container">
                  <Slide left>
                    <div className="tuition fees-table-item">
                      <h3 className="green">School Fees</h3>
                      <table className="box-shadow tution-table fees-table">
                        <tr>
                          <th>Section</th>
                          <th>Day</th>
                          <th>Boarding</th>
                        </tr>
                        <tr>
                          <td>Nursery</td>
                          <td>800,000 Ugshs</td>
                          <td>1,200,000 Ugshs</td>
                        </tr>
                        <tr>
                          <td>Primary</td>
                          <td>850,000 Ugshs</td>
                          <td>1,200,000 Ugshs</td>
                        </tr>
                      </table>
                    </div>
                  </Slide>
                  <Slide top>
                    <div className="uniforms fees-table-item">
                      <h3 className="blue">Uniforms</h3>
                      <table className="box-shadow uniforms-table fees-table">
                        <tr>
                          <th>Nursery</th>
                          <th>Primary Day</th>
                          <th>Primary Boarding</th>
                        </tr>
                        <tr>
                          <td>
                            <table>
                              <tr>
                                <th id="nested">Boys</th>
                                <th id="nested">Girls</th>
                              </tr>
                              <tr>
                                <td>295,000 Ugshs</td>
                                <td>295,000 Ugshs</td>
                              </tr>
                            </table>
                          </td>
                          <td>
                            <table>
                              <tr>
                                <th id="nested">Boys</th>
                                <th id="nested">Girls</th>
                              </tr>
                              <tr>
                                <td>370,000 Ugshs</td>
                                <td>370,000 Ugshs</td>
                              </tr>
                            </table>
                          </td>
                          <td>
                            <table>
                              <tr>
                                <th id="nested">Boys</th>
                                <th id="nested">Girls</th>
                              </tr>
                              <tr>
                                <td>420,000 Ugshs</td>
                                <td>420,000 Ugshs</td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </Slide>
                  <Slide right>
                    <div className="cocur fees-table-item">
                      <h3 className="red">
                        Special co-curricular lessons (optional){" "}
                      </h3>
                      <table className="box-shadow cocur-table fees-table">
                        <tr>
                          <th>Ballet</th>
                          <th>Scouts</th>
                          <th>Football</th>
                          <th>Swimming</th>
                        </tr>
                        <tr>
                          <td>100,000 Ugshs</td>
                          <td>50,000 Ugshs</td>
                          <td>50,000 Ugshs</td>
                          <td>50,000 Ugshs</td>
                        </tr>
                      </table>
                    </div>
                  </Slide>
                </div>
                <p className="uppercase center bold">
                  Admission fees to all classes: 50,000 Ugshs{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeesStructure;
